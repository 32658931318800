import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import BenchmarkingEmailOptOutPage from '../components/BenchmarkingEmailOptOutPage'
import OptOutSuccessPage from '../components/OptOutSuccessPage'
import { BASE_ROUTE } from '../utils/constants'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const AppContent = () => {
  const queryClient = new QueryClient()
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router basename={BASE_ROUTE}>
          <Routes>
            <Route path='/' element={<BenchmarkingEmailOptOutPage />} />
            <Route path='/success' element={<OptOutSuccessPage />} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </>
  )
}

export const App = () => (
  <div data-testid='app-insights-public'>
    <AppContent />
  </div>
)
