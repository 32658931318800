import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { getQueryParams, capitalizeFirstLetter } from '../utils/helpers'
import { useUnsubscribeUser } from '../api/benchmarking'
import { Navbar } from './Navbar'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useSentry } from 'banquet-runtime-modules'

const BenchmarkingEmailOptOutPage: React.FC = () => {
  const { captureException } = useSentry()
  const [isChecked, setisChecked] = useState<boolean>(true)
  const location = useLocation()
  const [isloading, setIsLoading] = React.useState<boolean>(false)
  const [alert, setAlert] = React.useState<boolean>(false)

  const { email, restaurantGuid, subscription } = getQueryParams(location)

  const { mutate: unsubscribeUser, data: unsubscribeUserData } =
    useUnsubscribeUser()

  const handleSubmit = () => {
    setIsLoading(true)
    unsubscribeUser({
      restaurantGuid,
      email,
      subscription: subscription.toUpperCase()
    })
  }

  // To remove alert after few seconds
  React.useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(false)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [alert])

  React.useEffect(() => {
    if (unsubscribeUserData) {
      // Status is only present in case of error
      if (unsubscribeUserData?.status) {
        const errorDataString = JSON.stringify(unsubscribeUserData)
        setIsLoading(false)
        setAlert(true)
        // Custom Error Capturing for entry
        captureException(
          new Error(
            `[error]: Message: User not subscribed, Location: BenchmarkingEmailOptOutPage , Error: ${errorDataString}`
          )
        )
      } else {
        // Navigate to the next page on success
        const currentUrl = window.location.origin + window.location.pathname
        //replace is used instead of push or href to avoid user to go back to this page
        window.location.replace(
          `${currentUrl}success?email=${email}&subscription=${subscription}`
        )
      }
    }
  }, [unsubscribeUserData, email, subscription, captureException])

  if (isloading) {
    return (
      <div>
        <Navbar />
        <div className='flex justify-center mx-auto mt-32 items-center'>
          <MerryGoRound />
          <h2 className=' type-headline-3 ml-2'>
            Unsubscribing please wait a moment...
          </h2>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Navbar />
      {alert && (
        <div>
          <Alert
            variant='error'
            className='mt-2 mr-4 float-right'
            onDismiss={() => setAlert(false)}
          >
            Something went wrong
          </Alert>
        </div>
      )}
      <div className='flex flex-col justify-center max-w-xl mx-auto px-4 mt-20'>
        <h2 className=' type-headline-2  '>
          Unsubscribe from {capitalizeFirstLetter(subscription)} email updates
        </h2>
        <p className=' type-large mt-4'>
          We received your request to unsubscribe {email}
        </p>
        <p className=' type-default mt-6'>
          Would you like to unsubscribe from:
        </p>

        <div className='flex items-center  mt-4'>
          <input
            type='checkbox'
            checked={isChecked}
            id='unsubscribe-checkbox'
            name='unsubscribe-checkbox'
            className='mr-3 w-4 h-4 ml-4'
            onChange={() => setisChecked(!isChecked)}
          />
          <label htmlFor='unsubscribe-checkbox'>
            All {capitalizeFirstLetter(subscription)} communications
          </label>
        </div>
        <Button
          variant='destructive'
          size='lg'
          className='mt-6 w-fit'
          disabled={!isChecked}
          onClick={handleSubmit}
        >
          Unsubscribe Now
        </Button>
      </div>
    </div>
  )
}

export default BenchmarkingEmailOptOutPage
