import { getLocale, Locale } from '@toasttab/buffet-pui-locale-utilities'
import {
  namespacedTFunction,
  hasResourceBundle,
  addResources
} from '@toasttab/buffet-pui-translation-utilities'
import enUS from './locales/en-US'
import esUS from './locales/es-US'

export const name = '@toasttab/buffet-pui-alerts'

export const defaultStrings = enUS

export type DefaultStrings = Record<keyof typeof defaultStrings, string>

const locales: Partial<Record<Locale, DefaultStrings>> = {
  'es-US': esUS
}

const DEFAULT_LOCALE = 'en-US'

/**
 * Loads the strings for a given locale.
 * @param locale - The locale for which to load the strings.
 */
export const loadStrings = (locale?: Locale) => {
  if (!locale) {
    locale = getLocale()
  }
  // Load defaults (will be a fallback if necessary)
  if (!hasResourceBundle(DEFAULT_LOCALE, name)) {
    addResources(DEFAULT_LOCALE, name, defaultStrings)
  }

  // Load locale file if bundle for language not yet loaded
  if (!hasResourceBundle(locale, name)) {
    addResources(locale, name, locales[locale])
  }
}

export const t = namespacedTFunction<DefaultStrings>(name)
