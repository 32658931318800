import { useMutation } from '@tanstack/react-query'
import { getG2FrontendUrl } from '../utils/helpers'

const BASE_EMAIL_API = getG2FrontendUrl()

export const useUnsubscribeUser = () => {
  return useMutation({
    mutationFn: ({
      restaurantGuid,
      email,
      subscription
    }: {
      restaurantGuid: string
      email: string
      subscription: string
    }) => {
      const path = '/brands/v1/google-ads/public/unsubscribe/'
      const body = JSON.stringify({
        subscription: subscription,
        email: email,
        restaurantGuid: restaurantGuid
      })

      return fetch(`${BASE_EMAIL_API}${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: body
      }).then(async (res) => {
        let result
        // reponse returns text on success and json on failure
        if (res.status === 200) {
          result = await res.text()
        } else {
          result = await res.json()
        }
        console.log('result', result)
        return result
      })
    },
    gcTime: Infinity
  })
}
