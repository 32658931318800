import React from 'react'

export const Navbar: React.FC = () => {
  return (
    <div className='bg-white flex items-center sticky w-screen top-0 z-20 print:hidden'>
      <img
        src='https://cdn.toasttab.com/static/f15a61f3b4ebca72b60a4dca4c813c424f2c2837/images/toast-iphone-icon.png'
        alt='toast-logo'
        className=' h-16 w-16'
      />
      <div className='px-6 flex-1 flex items-center space-x-3 lg:space-x-5 h-16 border-b'></div>
    </div>
  )
}
