import React from 'react'
import { useLocation } from 'react-router-dom'
import { getQueryParams, capitalizeFirstLetter } from '../utils/helpers'
import { Navbar } from './Navbar'
const OptOutSuccessPage: React.FC = () => {
  const location = useLocation()
  const { email, subscription } = getQueryParams(location)
  return (
    <>
      <Navbar />
      <div className='flex flex-col justify-center max-w-xl mx-auto mt-20'>
        <h2 className=' type-headline-2  '>Successfully unsubscribed</h2>
        <p className=' type-large mt-4'>
          You will no longer receive {capitalizeFirstLetter(subscription)}{' '}
          updates at {email}. Thank you for being part of our community.
        </p>
        <a href='/restaurants/admin/home' className='inline-link mt-4'>
          Go to Toast Web Home
        </a>
      </div>
    </>
  )
}

export default OptOutSuccessPage
