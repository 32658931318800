export default {
  success: {
    message: 'Success',
    description: 'Label for alert indicating that an action has been successful'
  },
  error: {
    message: 'Error',
    description: 'Label for alert indicating that an action has failed'
  },
  dismiss: {
    message: 'Dismiss',
    description: 'Label for alert indicating that an action has been dismissed'
  }
}
