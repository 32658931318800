import 'systemjs-webpack-interop/auto-public-path'
// IMPORTANT: Do not move or remove the above import. It must be at the top of this file. See https://www.npmjs.com/package/systemjs-webpack-interop#newer-versions-of-webpack
import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { loadTranslations } from '@local/translations'
import { themes } from '@local/themes'
import { App } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-app-insights-public-spa',
  rootComponent: App,
  sentry: {
    publicKey: '3ea3b224e8375608ab0a82052f7d33b6@o37442',
    projectId: '4508241931403264',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },
  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = [loadTranslations, ...reactLifecycles.bootstrap]
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'insights-public-spa'
